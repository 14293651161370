/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Una vez decidido que utilizaremos el modelo de fuente  abierta para nuestros diseños, tenemos que defenderlo de otras empresas que quieran copiarlo y hacer de el un sistema cerrado, para ello dejaremos de pensar en la filosofía del Open Source para introducirnos de lleno en la cultura Libre. ¿Pero que diferencia hay?"), "\n", React.createElement(_components.p, null, "La diferencia radica en que el Open Source puede contener y aceptar contenido privativo, es decir que si diseñamos un producto y lo licenciamos con una licencia Open Source como puede ser la MIT, cualquiera puede crear un contenido privativo con tu diseño. Con una licencia de Software/Hardware Libre (A partir de ahora SHL) como puede ser la GPL, otra persona no puede crear nada privativo partir de lo tuyo, tiene que ser necesariamente bajo la misma licencia, de esta forma aumentamos la comunidad y no perdemos nuestros derechos."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
